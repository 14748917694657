<template>
  <div>
    <div class="funxbox">
      <div class="box" @click="cardJump">
        <div class="num" v-if="carlength">{{ carlength }}</div>
        <div class="iconfont icon-gouwuche gouwuche"></div>
        <div class="txt">购物车</div>
      </div>
      <div class="box" @click="fkJump">
        <div class="iconfont icon-JGHandle JGHandle"></div>
        <div class="txt">提交反馈</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      carlength: 0,
    };
  },
  props: ["num"],
  watch: {
    num(val) {
      console.log(val);
      // this.carlength = this.carlength + val;
      this.get();
    },
  },
  created() {},
  methods: {
    get() {
      this.$get("/web/member")
        .then((res) => {
          window.localStorage.setItem("member", JSON.stringify(res.member));
          this.carlength = res.member.carlength;
        })
    },
    cardJump() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      if (!this.carlength) {
        this.$message.error("请先添加产品到购物车");
        return;
      }
      this.$router.push({
        path: "/cart",
        query: {},
      });
    },
    fkJump() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$router.push({
        path: "/fankui",
        query: {},
      });
    },
  },
  mounted() {
    setTimeout(() => {
      let member = window.localStorage.getItem("member");
      if (member) {
        this.carlength = JSON.parse(member).carlength;
      }
    }, 500);
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.funxbox {
  background-color: #fff;
  position: fixed;
  top: calc(50vh - 50px);
  right: 0;
  padding: 10px 7px 10px 10px;
  box-shadow: 0 0 8px 4px #ebebeb;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  .box {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    .num {
      position: absolute;
      top: -4px;
      right: 0;
      background-color: #f50505;
      color: #fff;
      border-radius: 15px;
      font-size: 12px;
      border: 2px solid #fff;
      padding: 0 6px;
    }
    .gouwuche {
      font-size: 26px;
    }
    .JGHandle {
      font-size: 22px;
    }
    .txt {
      font-size: 12px;
    }
  }
  .box:last-child {
    margin-bottom: 5px;
  }
}
</style>
